/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/bundle/bff/api/v1/bundle_service.proto" (package "verily_me.web.bundle.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { BundleService } from "./bundle_service";
import type { MarkBundleStepInProgressResponse } from "./bundle_service";
import type { MarkBundleStepInProgressRequest } from "./bundle_service";
import type { UpdateBundleStepsResponse } from "./bundle_service";
import type { UpdateBundleStepsRequest } from "./bundle_service";
import type { ListBundlesResponse } from "./bundle_service";
import type { ListBundlesRequest } from "./bundle_service";
import type { Bundle } from "./bundle_service";
import type { GetBundleRequest } from "./bundle_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { BatchGetBundlesResponse } from "./bundle_service";
import type { BatchGetBundlesRequest } from "./bundle_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * //////////////////////// Potential Errors //////////////////////////
 * BatchGetBundles
 * Scenario: bundles cannot be retrieved
 * Error: codes.NotFound
 * Scenario: cannot get actionGroups from action service
 * Error: codes.Internal
 * Scenario: bundles can not be properly made(cannot parse consent/info/survey names, unidentified bundle step types, etc)
 * Error: codes.Internal
 * GetBundle
 * Scenario: bundle cannot be retrieved
 * Error: codes.NotFound
 * Scenario: cannot get actionGroups from action service
 * Error: codes.Internal
 * Scenario: bundles can not be properly made(cannot parse consent/info/survey names, unidentified bundle step types, etc)
 * Error: codes.Internal
 * UpdateBundleSteps
 * Scenario: steps cannot be found
 * Error: codes.NotFound
 * Scenario: steps cannot be transitioned
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.bundle.bff.api.v1.BundleService
 */
export interface IBundleServiceClient {
    /**
     * @generated from protobuf rpc: BatchGetBundles(verily_me.web.bundle.bff.api.v1.BatchGetBundlesRequest) returns (verily_me.web.bundle.bff.api.v1.BatchGetBundlesResponse);
     */
    batchGetBundles(input: BatchGetBundlesRequest, options?: RpcOptions): UnaryCall<BatchGetBundlesRequest, BatchGetBundlesResponse>;
    /**
     * @generated from protobuf rpc: GetBundle(verily_me.web.bundle.bff.api.v1.GetBundleRequest) returns (verily_me.web.bundle.bff.api.v1.Bundle);
     */
    getBundle(input: GetBundleRequest, options?: RpcOptions): UnaryCall<GetBundleRequest, Bundle>;
    /**
     * @generated from protobuf rpc: ListBundles(verily_me.web.bundle.bff.api.v1.ListBundlesRequest) returns (verily_me.web.bundle.bff.api.v1.ListBundlesResponse);
     */
    listBundles(input: ListBundlesRequest, options?: RpcOptions): UnaryCall<ListBundlesRequest, ListBundlesResponse>;
    /**
     * @generated from protobuf rpc: UpdateBundleSteps(verily_me.web.bundle.bff.api.v1.UpdateBundleStepsRequest) returns (verily_me.web.bundle.bff.api.v1.UpdateBundleStepsResponse);
     */
    updateBundleSteps(input: UpdateBundleStepsRequest, options?: RpcOptions): UnaryCall<UpdateBundleStepsRequest, UpdateBundleStepsResponse>;
    /**
     * @generated from protobuf rpc: MarkBundleStepInProgress(verily_me.web.bundle.bff.api.v1.MarkBundleStepInProgressRequest) returns (verily_me.web.bundle.bff.api.v1.MarkBundleStepInProgressResponse);
     */
    markBundleStepInProgress(input: MarkBundleStepInProgressRequest, options?: RpcOptions): UnaryCall<MarkBundleStepInProgressRequest, MarkBundleStepInProgressResponse>;
}
/**
 * //////////////////////// Potential Errors //////////////////////////
 * BatchGetBundles
 * Scenario: bundles cannot be retrieved
 * Error: codes.NotFound
 * Scenario: cannot get actionGroups from action service
 * Error: codes.Internal
 * Scenario: bundles can not be properly made(cannot parse consent/info/survey names, unidentified bundle step types, etc)
 * Error: codes.Internal
 * GetBundle
 * Scenario: bundle cannot be retrieved
 * Error: codes.NotFound
 * Scenario: cannot get actionGroups from action service
 * Error: codes.Internal
 * Scenario: bundles can not be properly made(cannot parse consent/info/survey names, unidentified bundle step types, etc)
 * Error: codes.Internal
 * UpdateBundleSteps
 * Scenario: steps cannot be found
 * Error: codes.NotFound
 * Scenario: steps cannot be transitioned
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.bundle.bff.api.v1.BundleService
 */
export class BundleServiceClient implements IBundleServiceClient, ServiceInfo {
    typeName = BundleService.typeName;
    methods = BundleService.methods;
    options = BundleService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: BatchGetBundles(verily_me.web.bundle.bff.api.v1.BatchGetBundlesRequest) returns (verily_me.web.bundle.bff.api.v1.BatchGetBundlesResponse);
     */
    batchGetBundles(input: BatchGetBundlesRequest, options?: RpcOptions): UnaryCall<BatchGetBundlesRequest, BatchGetBundlesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetBundlesRequest, BatchGetBundlesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBundle(verily_me.web.bundle.bff.api.v1.GetBundleRequest) returns (verily_me.web.bundle.bff.api.v1.Bundle);
     */
    getBundle(input: GetBundleRequest, options?: RpcOptions): UnaryCall<GetBundleRequest, Bundle> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBundleRequest, Bundle>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListBundles(verily_me.web.bundle.bff.api.v1.ListBundlesRequest) returns (verily_me.web.bundle.bff.api.v1.ListBundlesResponse);
     */
    listBundles(input: ListBundlesRequest, options?: RpcOptions): UnaryCall<ListBundlesRequest, ListBundlesResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListBundlesRequest, ListBundlesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBundleSteps(verily_me.web.bundle.bff.api.v1.UpdateBundleStepsRequest) returns (verily_me.web.bundle.bff.api.v1.UpdateBundleStepsResponse);
     */
    updateBundleSteps(input: UpdateBundleStepsRequest, options?: RpcOptions): UnaryCall<UpdateBundleStepsRequest, UpdateBundleStepsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateBundleStepsRequest, UpdateBundleStepsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MarkBundleStepInProgress(verily_me.web.bundle.bff.api.v1.MarkBundleStepInProgressRequest) returns (verily_me.web.bundle.bff.api.v1.MarkBundleStepInProgressResponse);
     */
    markBundleStepInProgress(input: MarkBundleStepInProgressRequest, options?: RpcOptions): UnaryCall<MarkBundleStepInProgressRequest, MarkBundleStepInProgressResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<MarkBundleStepInProgressRequest, MarkBundleStepInProgressResponse>("unary", this._transport, method, opt, input);
    }
}
