import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  BatchGetBundlesRequest,
  Bundle,
  BundleStep,
  GetBundleRequest,
  MarkBundleStepInProgressRequest,
  StepStatus,
  UpdateBundleStepsRequest,
} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/bundle_service';
import {BundleServiceClient} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/bundle_service.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {
  BundleStatus,
  getBundleStatuses,
  getStatusOfBundle,
} from '../bundle-utils';

export type BundleWithStatus = {
  bundle: Bundle;
  status?: BundleStatus;
};

// Cache for Bundles
const CACHE = new Map<string, BundleWithStatus>();

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class BundleService {
  private bundleServiceClient!: BundleServiceClient;

  async getClient() {
    if (!this.bundleServiceClient) {
      const interceptors: RpcInterceptor[] = [await Auth.getInterceptor()];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.bundleServiceClient = new BundleServiceClient(transport);
    }

    return this.bundleServiceClient;
  }

  async getBundle(bundleName: string): Promise<{
    bundlePlanId: string;
    bundlePlanName: string;
    name: string;
    steps: Array<BundleStep>;
    redirectUrlOnCompletion: string;
  }> {
    // Check if the bundle has been cached
    if (!CACHE.has(bundleName)) {
      const request: GetBundleRequest = {bundleName};
      CACHE.set(bundleName, {bundle: await this.callGetBundle(request)});
    }
    const bundle = CACHE.get(bundleName);
    if (!bundle) {
      throw Error(`Could not find bundle ${bundleName}`);
    }
    return Promise.resolve({...bundle.bundle});
  }

  updateBundleStepsInCache(bundleName: string, bundleSteps: Array<BundleStep>) {
    if (CACHE.has(bundleName)) {
      const bundle = CACHE.get(bundleName)?.bundle;
      if (!bundle) {
        throw Error(`Could not find bundle ${bundleName}`);
      }
      CACHE.set(bundleName, {
        bundle: {
          name: bundleName,
          steps: bundleSteps,
          bundlePlanId: bundle.bundlePlanId,
          bundlePlanName: bundle.bundlePlanName,
          redirectUrlOnCompletion: bundle.redirectUrlOnCompletion,
        },
      });
    }
  }

  /**
   * Performs a batch fetch for all of the given bundle names and conditionally caches them.
   *
   * @param bundleNames Names of the bundles you want to fetch
   * @returns List of bundles
   */
  async batchGetBundles(bundleNames: string[]): Promise<Bundle[]> {
    const request: BatchGetBundlesRequest = {bundleNames};
    const bundleServiceClient = await this.getClient();
    const bundles = (await bundleServiceClient.batchGetBundles(request))
      .response.bundles;
    bundles.forEach((bundle: Bundle) => {
      const cachedBundle = CACHE.get(bundle.name)?.bundle;
      // Don't overwrite the cache if the bundle is already in-cache and complete
      if (
        !cachedBundle ||
        getStatusOfBundle(cachedBundle.steps) !== BundleStatus.COMPLETED
      ) {
        CACHE.set(bundle.name, {bundle});
      }
    });
    return Promise.resolve(bundles);
  }

  /**
   * Retrieves a list of bundles from the bundle service client and caches them.
   * @async
   * @function listBundles
   * @returns {Promise<Bundle[]>} A promise that resolves with an array of Bundle objects.
   */
  async listBundles(): Promise<Bundle[]> {
    const bundleServiceClient: BundleServiceClient = await this.getClient();
    const bundles = (await bundleServiceClient.listBundles({})).response
      .bundles;
    bundles.forEach(bundle => CACHE.set(bundle.name, {bundle}));
    return bundles;
  }

  async setBundleStepInProgress(
    bundleName: string,
    bundleStep: BundleStep
  ): Promise<void> {
    // Set bundle & individual bundle step to be in progress in local cache
    if (CACHE.has(bundleName)) {
      const bundle = CACHE.get(bundleName)?.bundle;
      if (!bundle) {
        throw Error(`Could not find bundle ${bundleName}`);
      }
      const newSteps = bundle.steps.map(step =>
        step.name === bundleStep.name
          ? {...step, ...bundleStep, status: StepStatus.IN_PROGRESS}
          : step
      );
      CACHE.set(bundleName, {
        bundle: {
          name: bundleName,
          steps: newSteps,
          bundlePlanId: bundle.bundlePlanId,
          bundlePlanName: bundle.bundlePlanName,
          redirectUrlOnCompletion: bundle.redirectUrlOnCompletion,
        },
        status: BundleStatus.IN_PROGRESS,
      });
    }

    // Update the bundle step to be in progress in the backend
    const request: MarkBundleStepInProgressRequest = {bundleName, bundleStep};
    this.callMarkBundleStepInProgress(request);
  }

  async updateBundleSteps(
    bundleName: string,
    bundleSteps: Array<BundleStep>,
    status?: BundleStatus
  ): Promise<BundleStep[]> {
    // Update the cache, as the record is now outdated
    if (CACHE.has(bundleName)) {
      const bundle = CACHE.get(bundleName)?.bundle;
      if (!bundle) {
        throw Error(`Could not find bundle ${bundleName}`);
      }
      const newSteps = bundle.steps.map(
        step => bundleSteps.find(newStep => newStep.name === step.name) || step
      );
      CACHE.set(bundleName, {
        bundle: {
          name: bundleName,
          steps: newSteps,
          bundlePlanId: bundle.bundlePlanId,
          bundlePlanName: bundle.bundlePlanName,
          redirectUrlOnCompletion: bundle.redirectUrlOnCompletion,
        },
        status,
      });
    }
    const request: UpdateBundleStepsRequest = {bundleName, bundleSteps};
    return await this.callUpdateBundleSteps(request);
  }

  private async callGetBundle(request: GetBundleRequest): Promise<Bundle> {
    const bundleServiceClient = await this.getClient();

    return bundleServiceClient.getBundle(request).response;
  }

  private async callMarkBundleStepInProgress(
    request: MarkBundleStepInProgressRequest
  ): Promise<void> {
    const bundleServiceClient = await this.getClient();

    await bundleServiceClient.markBundleStepInProgress(request);
  }

  private async callUpdateBundleSteps(
    request: UpdateBundleStepsRequest
  ): Promise<BundleStep[]> {
    const bundleServiceClient = await this.getClient();

    const response = await bundleServiceClient.updateBundleSteps(request)
      .response;
    if (!response.nextSteps) {
      return [];
    }
    return response.nextSteps;
  }
}

export const getBundleStatusesFromCache = () => {
  return getBundleStatuses(CACHE);
};

export default new BundleService();
